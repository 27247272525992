import React from "react";
import HomeVideo from "../../assets/images/test2.gif"

export default function HomeBanner() {
  return (
    <div className="header-content min-h-screen w-full flex items-center justify-center text-sky-800 dark:text-sky-500">
      <div className="flex flex-col items-center md:w-10/12 w-11/12 py-20">
        <div className="grid gap-10 md:grid-cols-2 w-full">
          <div className="mt-16 md:mt-0 md:flex flex-col justify-center">
            <h1
              data-aos="fade-right"
              className="text-sky-800 dark:text-white text-3xl md:text-6xl font-bold"
            >
              React{" "}
              <span className="dark:text-sky-500">
                UI
              </span>
              {" "}Builder
            </h1>
            <p
              data-aos="fade-right"
              data-aos-delay="200"
              className="mt-5 mb-3 text-gray-500 dark:text-gray-400"
            >
             ⚡Designed to streamline interface development. React UI Builder tool offers intuitive drag and drop features, empowering rapid creation of sophisticated user interfaces for enhanced application design and usability Our <strong>React website builder</strong> is designed to be user-friendly and powerful.
            </p>
            <a href="https://reactui-builder.com/builder" target="__blank"
              data-aos="fade-right"
              data-aos-delay="300"
              className="mt-5 w-48 bg-sky-100 dark:bg-sky-500 dark:text-white text-sky-800 transition-all px-8 py-3 rounded-full hover:shadow-xl hover:shadow-black/10"
            >
              View Builder
            </a>
          </div>
          <div style={{width:"100%",height:"100%", borderRadius:"10px"}}>
            {/* <img style={{borderRadius:"10px"}} src="/img/dashboard.png" className="w-full" alt="" /> */}

            <video style={{width:"100%",height:"100%", borderRadius:"10px"}} loop autoPlay={true} muted>
            <source src="/img/home.mp4" type="video/mp4"/>
          </video>
            
            <div
          // className="embed-responsive embed-responsive-4by3 relative w-full overflow-hidden"
        >
          {/* <video class="w-full shadow-lg" autoPlay loop muted> */}
          {/* <source autoPlay src={HomeVideo} type="video/mp4" /> */}
        {/* </video> */}


        </div>
          </div>
        </div>
        <div className="w-full mt-24 mb-5 grid md:grid-cols-4 gap-10">
          <div>
            <h1 className="text-4xl font-bold">8 Million+</h1>
            <p className="mt-2 text-gray-500 dark:text-gray-400 ">
              React is downloaded over 8 million times every month from npm
            </p>
          </div>
          <div>
            <h1 className="text-4xl font-bold">94,000+</h1>
            <p className="mt-2 text-gray-500 dark:text-gray-400 ">
            React is utilized by more than 94,000 websites across the globe
            </p>
          </div>
          <div>
            <h1 className="text-4xl font-bold">200,000+</h1>
            <p className="mt-2 text-gray-500 dark:text-gray-400 ">
            React is ranked as one of the most starred projects on GitHub, with over 200,000 stars and contributions 
            </p>
          </div>
          <div>
            <h1 className="text-4xl font-bold">1,500+</h1>
            <p className="mt-2 text-gray-500 dark:text-gray-400">
            React boasts a large and active community of developers, with over 1,500 contributors
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
