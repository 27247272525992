import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeDropDown from "./HomeDropDown";
import DarkToggle from "./DarkToggle";
import { Menu } from "@headlessui/react";
import {FaRenren } from "react-icons/fa";

export default function Nav({ darkToggle, setDarkToggle, showPopup }) {
 
 
  const [active, setActive] = useState(false);
  const showMenu = () => {
    setActive(!active);
  };


  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  return (
    <div className="dark:text-white w-full p-4 text-sm bg-white shadow-md dark:shadow-gray-800 dark:bg-primary sticky top-0 z-40">
      <div className="flex items-center justify-between lg:grid grid-cols-3">
        <Link
          to="/"
          className="text-sky-800 text-xl md:text-2xl dark:text-sky-400"
        >
          {darkToggle ? (
            <img  src="/img/logo-no-background.png" className="h-7 mr-2" alt="React UI Builder Interface"  />
          ) : (
            <img src="/img/logo-black.png" className="h-7 mr-2" alt="React UI Builder Interface"  />
          )}
        </Link>
        <div className="hidden lg:flex items-center text-sky-800 dark:text-sky-400">

        {/* <HomeDropDown /> */}

          {/* <Link
            to="/about"
            className="border-b-2 border-white hover:border-sky-800 transition-all dark:hover:border-sky-500 dark:hover:border-b-2 mx-3 py-1 dark:border-b-2 dark:border-primary"
          >
            About
          </Link> */}

          
        </div>
        <div className="hidden lg:flex items-center justify-end">
          <DarkToggle darkToggle={darkToggle} setDarkToggle={setDarkToggle} />
            <a
              href="https://reactui-builder.com/builder"
              target="__blank"
              className="border-b-2 flex items-center gap-2 border-white hover:border-sky-800 transition-all dark:hover:border-sky-500 dark:hover:border-b-2 mx-3 py-1 dark:border-b-2 dark:border-primary"
            >
              <FaRenren />
              Go to Builder
            </a>

          {/* {<Wallet />} */}
        </div>
        {/* <!-- Hamburger Button --> */}

        <button
          onClick={showMenu}
          className="block text-sky-800 dark:text-sky-400 text-lg font-semibold lg:hidden focus:outline-none border-b-2 flex items-center gap-2 border-white hover:border-sky-800 transition-all dark:hover:border-sky-500 dark:hover:border-b-2 mx-3 py-1 dark:border-b-2 dark:border-primary"
        >
          {active ? "CLOSE" : "EXPLORE"}
        </button>
      </div>

      {/* <!-- Mobile Menu --> */}
      <div
        id="menu"
        className={
          active
            ? "relative lg:hidden p-1 mt-6 text-xl  text-black dark:text-white rounded-lg flex-auto h-screen"
            : "hidden"
        }
      >
        <div className="flex flex-col justify-center text-start mt-6">
        
         
            <a
              className="border-b border-white/25 py-4 flex items-center"
              href="https://reactui-builder.com/builder"
              target="__blank"
            >
              <FaRenren className="mr-3 text-sky-300" />
              Go to Builder
            </a>
  
        
          
          <div
            onClick={showMenu}
            className="border-b border-white/25 py-4 flex items-center"
          >
          <input
            type="button"
            value="Switch Theme"
            className="font-medium  dark:text-white  cursor-pointer"
            onClick={() => setDarkToggle(!darkToggle)}
          />

            <DarkToggle darkToggle={darkToggle} setDarkToggle={setDarkToggle} />
          </div>
        
        </div>
      </div>
    </div>
  );
}
