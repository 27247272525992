import React from "react";

export default function BenefitsOfJoining() {
  return (
    <div id="preSaleDetails" className="w-full flex flex-col py-16 md:pb-20 items-center text-sky-800 dark:text-white ">
      <div className="grid md:gap-10 md:grid-cols-discover w-10/12 lg:w-6/12">
        <div>
          <p className="text-sm border-b pb-2 font-medium border-sky-800 dark:border-gray-100 mt-4 dark:text-sky-500">
            WHERE WE GROW
          </p>
        </div>
        <div className="md:p-2 mt-5 md:mt-0">
          <h1 className="text-4xl font-bold">
            Benefits of using{" "}
            <span className="dark:text-sky-500">React UI Builder</span>
          </h1>
          <p className="mt-3 text-gray-500 dark:text-gray-400">
          ⚡React UI builder empowers, simplifies and accelerates the development of complex user interfaces, enhancing productivity and enabling rapid prototyping of interactive web applications.
          </p>
          <br />
        
        </div>
      </div>
      <div className="w-10/12 lg:w-9/12 grid gap-7 md:grid-cols-5 mt-10">
        <div>
          <div className="bg-sky-800 text-white flex items-center justify-center dark:bg-sky-500 w-16 h-16 rounded-full">
            01
          </div>
          <div className="bg-gray-100 shadow-xl shadow-sky-500/30 dark:bg-content bg-center bg-cover p-5 mt-5 rounded-lg">
            <h1 className="text-lg font-bold">Faster Dev</h1>
    
          </div>
        </div>
        <div>
          <Step
            number="02"
            title="Responsive design"
        
          />
        </div>
        <div>
          <Step
            number="03"
            title="Production code"
      
          />
        </div>
        <div>
          <Step number="04" title="Easy integration"  />
        </div>
        <div>
          <Step number="05" title="Global community" />
        </div>
      </div>
    </div>
  );
}

function Step({ number, title, supply, price }) {
  return (
    <>
      <div className="bg-sky-800 text-white flex items-center justify-center dark:bg-sky-500 w-16 h-16 rounded-full">
        {number}
      </div>
      <div className="bg-gray-100 dark:bg-content bg-center bg-cover p-5 mt-5 rounded-lg">
        <h1 className="text-lg font-bold">{title}</h1>

      </div>
    </>
  );
}
