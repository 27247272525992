import React, {useState} from "react";
import emailjs from '@emailjs/browser';

export default function Contact() {
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    //post request to send email
    const res = fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
        service_id: 'service_zqghn9m',
        template_id: 'template_y3a6dwp',
        user_id: '-UBDc2TlgC3ZfkCHA',
        template_params: {
            "from_name": `${contactForm.firstName + " " + contactForm.lastName}`,
            "from_email": `${contactForm.email}`,
            "from_number": `${contactForm.contactNumber}`,
            "message": `${contactForm.message}`,
        }
        }),
      }
    ).then(res=>{
      if(res.status !== 200){
      setMessage("Something went wrong, Message not sent");
      }else {
        setMessage("Message sent successful");
        setContactForm({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          message: "",
        })
      }
    })
    .catch(err=>{
      setMessage("Something went wrong, Message not sent");
    })
    
  };
  const changeHandler = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };
  const messageHandler = () => {
    setMessage("");
  };

  return (
    <div id="contact" className="w-full flex flex-col py-14 md:2 text-smy-20 items-center text-sky-800 dark:text-gray-300 ">
      <div className="grid md:gap-10 md:grid-cols-2 w-10/12 lg:w-9/12">
        <div>
          <p className="text-sm w-32 border-b pb-2 font-medium border-sky-800 dark:border-gray-100 mt-4 uppercase dark:text-sky-500">
            Contact Us
          </p>
          <h1 className="text-4xl font-bold mt-5">
            Hi! <span className="dark:text-sky-500">Got a question</span>
          </h1>
          <p className="mt-5 text-sky-800 dark:text-white">
            Feel free to reach out to us. One of our team members will get back to you as soon as possible
          </p>
        </div>
        <div className="md:p-2 mt-5 md:mt-0">
          <form
            onSubmit={handleSubmit}
            action="mailto:uitdsc@gmail.com"
            method="post"
            encType="text/plain"
          >
            <div className="grid lg:grid-cols-2 gap-5 w-full">
              <input
                placeholder="First Name"
                type="text"
                name="firstName"
                onChange={changeHandler}
                required
                value={contactForm.firstName}
                className="contactFormInput dark:text-sky-500"
              />
              <input
                placeholder="Last Name"
                type="text"
                name="lastName"
                onChange={changeHandler}
                value={contactForm.lastName}
                required
                className="contactFormInput dark:text-sky-500"
              />
              <input
                placeholder="Email"
                type="Email"
                name="email"
                required
                value={contactForm.email}
                onChange={changeHandler}
                className="contactFormInput dark:text-sky-500"
              />
              <input
                placeholder="Contact Number"
                required
                type="text"
                name="contactNumber"
                value={contactForm.contactNumber}
                onChange={changeHandler}
                className="contactFormInput dark:text-sky-500"
              />
             
            </div>
            <input
              placeholder="How can we help?"
              type="text"
              name="message"
              required
              value={contactForm.message}
              onChange={changeHandler}
              className="contactFormInput dark:text-sky-500 mt-8"
            />
           
            <br />

            {message && (
              <div
                onClick={messageHandler}
                className="w-full h-screen bg-black/30 z-50 fixed top-0 left-0 flex items-center justify-center"
              >
                <div className="bg-gray-800/80 p-3 shadow-xl shadow-sky-700/30 backdrop-blur-md rounded-xl">
                  <div className="p-10 px-16 font-medium bg-white dark:bg-content rounded-lg max-w-md text-xl text-center text-sky-400">
                    <p className="dark:text-sky-500">{message}</p>
                  </div>
                </div>
              </div>
            )}

            <button
              disabled={message}
              type="submit"
              className="py-4 mt-7 px-8 bg-sky-100 text-sky-800 dark:bg-sky-500 rounded-full dark:text-white dark:hover:bg-sky-600 hover:shadow-lg hover:shadow-sky-500/25 transition-all"
              style={{marginTop:"40px"}}
           >
              Send Message
            </button>
          </form>
          <br />
          <p className="text-sm text-sky-800 dark:text-white">
            You can also email us directly at{" "}
            <a href="uibuilderreact@gmail.com" className="text-sky-500">
            uibuilderreact@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
