import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function ScrollToTopButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      onClick={scrollToTop}
      className="rounded-full fixed bottom-0 right-0 m-10 bg-sky-800 dark:bg-sky-500 shadow-xl shadow-black/10 text-white flex items-center justify-center dark:shadow-gray-200 hover:bg-sky-500 dark:hover:bg-sky-400 cursor-pointer h-12 w-12 p-2 z-20"
      style={{backgroundColor:"#4a9d9c"}}
    >
      <ChevronUpIcon className="w-7" />
    </div>
  );
}
