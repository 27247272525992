import React from "react";
import {Link} from "react-scroll"

export default function AfterBanner({showPopup}) {
  return (
    <div
      id="mission&vision"
      className="relative flex justify-center w-full py-16 md:py-20 text-sky-800 dark:text-white"
    >
      <div className="w-10/12 md:w-9/12 grid md:grid-cols-2 gap-12">
        
        <div>
          <h5 className="text-3xl text-center md:text-left md:text-5xl font-bold">
            Mission and Vision<br></br>
          </h5>
          
        <br/>

        <div className=" w-full col-span-3  flex items-center rounded-lg p-6">

        <div id="Core-Team-Page-Circle">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="300px"
                        height="300px"
                        viewBox="0 0 300 300"
                        enableBackground="new 0 0 300 300"
                        xmlSpace="preserve"
                      >
                        <defs>
                          <path
                            id="circlePath"
                            d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                          />
                        </defs>
                        <circle cx={150} cy={100} r={75} fill="none" />
                        <g>
                          <use xlinkHref="#circlePath" fill="none" />
                          <text fill="#4a9d9c">
                            <textPath xlinkHref="#circlePath">
                              REACT UI BUILDER{" "}
                            </textPath>
                          </text>
                        </g>
                      </svg>
                    </div>

      
        </div>



        </div>
        <div className="text-center md:text-left">
          <p className="text-gray-500 dark:text-gray-400">
          To empower developers with an intuitive and powerful toolset that simplifies the creation of sophisticated user interfaces using React, enhancing productivity and enabling rapid application development.
          </p>
          <br />
          <p className="text-gray-500 dark:text-gray-400">
         
          Our vision is to become the leading platform for React developers worldwide, providing innovative solutions that streamline UI design and development processes, foster collaboration, and accelerate the delivery of exceptional digital experiences. 
          </p>
          <div className="flex flex-col items-center md:items-start">
            <h1 className="text-xl text-center md:text-left mt-10 md:text-3xl font-bold">
            Approach us for your queries or feedbacks 
            </h1>
            <Link target="__blank"  className="py-4 mt-7 px-8 bg-sky-100 text-sky-800 dark:bg-sky-500 rounded-full dark:text-white dark:hover:bg-sky-600 hover:shadow-lg hover:shadow-sky-500/25 transition-all"
             to="contact"
             smooth="true"
             spy="true"
            
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
