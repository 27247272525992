import React from "react";
import MissionAndVision from "../Components/MissionAndVision";
import HomeBanner from "../Components/Banners/HomeBanner";

import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Nav from "../Components/Navbar/Nav";
// import News from "../Components/News";
import BenefitsOfJoining from "../Components/BenefitsOfJoining";
import Team from "../Components/Team";

export default function Home({
  darkToggle,
  setDarkToggle
}) {


  return (
    <>
      <Nav
        darkToggle={darkToggle}
        setDarkToggle={setDarkToggle}
      />

      <HomeBanner />


      <MissionAndVision  />
      
      <BenefitsOfJoining />

      

      <Team />

      <Contact />
      <Footer darkToggle={darkToggle}/>
    </>
  );
}
