import React from "react";
import {FaLinkedinIn,FaTwitter,FaGithub} from "react-icons/fa"

export default function Team() {
  const Member = (img, name, desg, twitter, linkedIn, github) => (
    <div className="w-full flex flex-col items-center">
      <img src={`/img/${img}`} style={{borderRadius:"20px"}} alt="" />
      <h1 className="text-lg font-bold mt-2 mt-5 text-gray-500 dark:text-gray-400">{name}</h1>
      <h5 className="font-medium mt-1">{desg}</h5>
      <div className="flex items-center mt-2">
       {twitter !=="" && <a
          href={`https://www.twitter.com/${twitter}`}
          target="_blank"
          rel="noreferrer"
        >
            <FaTwitter
            className="cursor-pointer h-4 mx-2"
          />
        </a>
        }
        <a
          href={`https://www.linkedin.com/${linkedIn}`}
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn
            className="cursor-pointer h-4 mx-2"
          />
        
        </a>
        <a
          href={`https://www.github.com/${github}`}
          target="_blank"
          rel="noreferrer"
        >
          <FaGithub
            className="cursor-pointer h-4 mx-2"
          />
        
        </a>
      </div>
    </div>
  );
  return (
    <div
      id="team"
      className="w-full  flex flex-col py-16 md:py-16 items-center text-sky-800 dark:text-white"
    >
      <div className="flex flex-col items-center w-10/12 lg:w-9/12">
        <div>
          <p className="text-sm border-b pr-10 pb-2 font-medium border-sky-800 dark:border-gray-100 dark:text-white">
            TEAM
          </p>
        </div>
        <div className="md:p-2 mt-5">
          <h1 className="text-4xl font-bold capitalize">
            Experienced and{" "}
            <span className="dark:text-sky-500">Professional team</span>
          </h1>
        </div>
      </div>
      <div className="w-10/12 dark:text-white rounded-xl mt-10 grid lg:grid-cols-3 gap-5   ">
        {Member("founder1.jpeg", "Syed Hamza Hoda", "Founder | CTO", "syedhamzahoda", "in/syed-hamza-hoda-83ab3a207/","hamzahoda")}

        {Member("founder2.jpeg", "Muhammad Rayyan", "Founder", "", "in/athar-rayyan/","Muhammad-Rayyan1")}
        
        {Member("founder3.jpeg", "Abdur Rafay", "Founder", "", "in/abdur-rafay27/","arafayr")}
      </div>
    </div>
  );
}
