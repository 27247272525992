import React from "react";

export default function Footer({darkToggle}) {
  return (
    <div className="w-full border-b border-t dark:border-white/10 flex flex-col py-16 md:py-20 items-center text-sky-800 bg-gray-100 dark:bg-content bg-center bg-cover dark:text-white">


      <div className="w-11/12 md:w-10/12 gap-5 grid lg:grid-cols-3">
       
       
        <div>
       
        {darkToggle ? (
            <img  src="/img/logo-no-background.png" className="h-7 mr-2" alt="React UI Builder Interface"  />
          ) : (
            <img src="/img/logo-black.png" className="h-7 mr-2" alt="React UI Builder Interface"  />
          )}

          <h1 className="text-3xl dark:text-sky-500 font-bold"></h1>
          <p className="mt-2 text-sm">
          The primary goal of the React UI Builder is to assist developers in achieving a harmonious balance between theoretical knowledge and practical application. Excelling in development isn't solely about academic prowess; it's about fostering determination and a passion for innovation, driving impactful solutions that resonate in the world of technology.{" "}
          </p>
        </div>
        
        {/* <div></div> */}
        
        {/* <div>
          <h1 className="uppercase text-lg font-bold dark:text-sky-500">
            Follow Us
          </h1>
          <div className="grid grid-cols-4 md:grid-cols-4 gap-3 mt-3">
            <SocialIcon
              title="Instagram"
              img="/img/InstagramLogo.png"
              link="instagram.com/gdscuit/"
            />
            <SocialIcon
              title="LinkedIn"
              img="/img/linkedin.png"
              link="linkedin.com/company/dscuit/"
            />
            <SocialIcon
              title="Facebook"
              img="/img/facebook.png"
              link="facebook.com/gdscuitu"
            />
            <SocialIcon
              title="Twitter"
              img="/img/twitter.png"
              link="twitter.com/dscuit_khi"
            />
          
          </div>
        </div> */}

      </div>
      <div className="w-11/12 md:w-10/12 mt-16 text-gray-500 border-t pr-10 pb-2 font-medium border-sky-800 dark:border-gray-100 dark:text-white">
        <p>Copyright © 2024 Syed Hamza Hoda, All rights reserved.</p>
      </div>
    </div>
  );
}

