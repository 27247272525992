import React, { useState } from "react";
import Home from "./Pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";

export default function Router() {
  const [darkToggle, setDarkToggle] = useState(true);
 
  return (
    <div className={`${darkToggle && "dark bg-primary"}`}>

      <Routes>
       
       


        <Route
          path="/"
          element={
            <Home
              darkToggle={darkToggle}
              setDarkToggle={setDarkToggle}
            />
          }
        />


      </Routes>
    </div>
  );
}
