import { MoonIcon, SunIcon } from "@heroicons/react/20/solid";

export default function DarkToggle({ darkToggle, setDarkToggle }) {
//   const [darkToggle, setDarkToggle] = useState(true);
  return (
    <div
      onClick={() => setDarkToggle(!darkToggle)}
      className="px-3 py-2 cursor-pointer text-sm text-sky-800 hover:text-sky-400 dark:text-sky-300 transition-all border-sky-300 dark:hover:text-sky-100 dark:hover:border-sky-200"
    >
      {darkToggle ? (
        <div className="flex item-center">
          <SunIcon className="h-5 ml-2" title="Switch Theme" />
        </div>
      ) : (
        <div className="flex items-center">
          <MoonIcon className="h-4 ml-2" title="Switch Theme" />
        </div>
      )}
    </div>
  );
}
